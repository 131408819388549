



















import { reactive, toRefs, ref, Ref, computed, watchEffect } from '@vue/composition-api';
import axios from 'axios';
import ATeleInput from '@/components/atoms/ATeleInput.vue';

interface IUserEmailForm {
  email: string;
  password: string;
  type: string;
  terms: boolean;
}

interface IUserPhoneForm {
  phone_number: string;
  type: string;
  terms: boolean;
}

interface IUi {
  msg: string;
  type: string;
  loading: boolean;
}

export default {
  name: 'Signup',
  components: {
    ATeleInput
  },

  setup(props: any, ctx) {
    const otpMsg = ref('');
    const dialog: Ref<boolean> = ref(false);
    const show1: Ref<boolean> = ref(false);
    const isOtpSent = ref(false);
    const isEmail = ref(false);
    const isPhone = ref(true);
    const verifying = ref(false);
    const isPhoneValid = ref(false);
    const otp = ref('');
    const captchaInput = ref('');
    const captchaImg = ref('');
    const stateEmail = reactive({
      email: '',
      password: '',
      type: 'email_signup',
      terms: false,
      error: ''
    });
    const statePhone = reactive({
      type: 'phone_number_signup',
      phone_number: '',
      formatted_number: '',
      terms: false
    });
    const {
      root: { $route, $router }
    } = ctx;
    const redirectPath = $route.query?.redirect;

    const param: string = (ctx.root.$route.query.email as string) || '';
    stateEmail.email = param;

    const ui: IUi = reactive({
      msg: '',
      type: 'success',
      loading: false
    });

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted) {
        statePhone.formatted_number = `+${val.countryCallingCode}${val.formatted}`;
        isPhoneValid.value = val.valid;
        ctx.emit('getData', statePhone);
      } else {
        statePhone.formatted_number = '';
        ctx.emit('getData', statePhone);
      }
    }

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (
        statePhone.phone_number.includes(substring) ||
        statePhone.phone_number.includes(substring1)
      ) {
        return false;
      }

      return true;
    });

    const isValidOtp = computed(() => {
      if (otp.value.length === 6 && captchaInput.value.length === 4) {
        return true;
      }
      return false;
    });

    async function removeGuardianFlag() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_AGREEMENT_FLAG;

        const res = await axios.patch(
          `${API_ENDPOINT}?student_id=${ctx.root.$route.params.studentId}`
        );
      } catch (error) {
        console.log(error);
      }
    }

    async function signIn() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTH0;

        const stateSms = {
          phone_number: statePhone.formatted_number,
          type: 'phone_number_signin'
        };

        const resp = await axios.post(`${API_ENDPOINT}/sign-in`, stateSms, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (resp.status === 200) {
          ui.type = 'success';
          ui.msg = resp.data.message;
          isOtpSent.value = true;
          ctx.root.$router.push({
            name: 'login',
            query: { otpSent: true, phone: statePhone.formatted_number }
          });
          removeGuardianFlag();
        }
      } catch (error) {
        ui.msg = error?.response?.data?.error?.description;
        ui.type = 'error';
      }
    }

    const submit = async (): Promise<void> => {
      ui.loading = true;

      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTH0;

        const phnPayload = {
          ...statePhone,
          phone_number: statePhone.formatted_number
        };

        const resp = await axios.post(`${API_ENDPOINT}/sign-up`, phnPayload, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        ui.type = 'success';
        ui.msg = resp.data.message;
        captchaImg.value = resp.data.data.captcha;
        isOtpSent.value = true;
        localStorage.setItem('is-guardian', ctx.root.$route.params.studentId);
        ctx.root.$router.push({
          name: 'signup',
          query: {
            otpSent: true,
            captchaImg: resp.data.data.captcha,
            phone: statePhone.formatted_number
          }
        });
      } catch (error) {
        await signIn();
        ui.msg = error?.response?.data?.error?.description
          ? error?.response?.data?.error?.description
          : 'Could not signup';
        ui.type = 'error';
      }
      ui.loading = false;
    };
    const fetching = ref(false);

    watchEffect(() => {
      if (isPhoneValid.value && isPhoneCheck.value) {
        ctx.emit('validatePhone', true);
      } else {
        ctx.emit('validatePhone', false);
      }
    });

    return {
      ...toRefs(stateEmail),
      ...toRefs(statePhone),
      ...toRefs(ui),
      submit,
      isEmail,
      dialog,
      show1,
      redirectPath,
      fetching,
      isOtpSent,
      isPhone,
      otp,
      verifying,
      otpMsg,
      isValidOtp,
      isPhoneValid,
      getValidation,
      isPhoneCheck,
      captchaImg,
      captchaInput,
      removeGuardianFlag
    };
  }
};
