




























































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useDbState, useDbGetters } from '@/store';
import axios from 'axios';
import { ObjectId } from 'bson';
import MSignup from '@/components/molecules/m-signup.vue';
import MAgreementGuardian from '@/components/molecules/m-agreement-guardian.vue';
import MAgreementStudent from '@/components/molecules/m-agreement-student.vue';
import MAgreementConsent from '@/components/molecules/m-agreement-consent.vue';

export default defineComponent({
  name: 'AgreementAdk',

  components: {
    MAgreementGuardian,
    MAgreementConsent,
    MAgreementStudent,
    MSignup
  },

  setup(props, ctx) {
    const isUserInvalid = ref(false);
    const isGuardianInvalid = ref(false);
    const isUserPhoneValid = ref(false);
    const isGuardianPhoneValid = ref(false);
    const { collection } = useDbGetters(['collection']);
    const agreementContent = ref('');
    const guardianData = ref();
    const submitting = ref(false);
    const guardianTerms = ref(false);
    const userTerms = ref(false);
    const isSSN = ref(false);
    const { user } = useDbState(['user']);
    const offerAlert = ref({
      color: '',
      msg: ''
    });
    const fetching = ref(false);
    const student = ref();
    const studentData = ref();
    const isSignupValid = ref(false);
    const phonePayload = ref();
    const signUp = ref();
    const isSigned = ref(false);

    async function getStudent() {
      student.value = await collection.value!('Student').findOne({
        _id: new ObjectId(ctx.root.$route.params.studentId)
      });

      const offerIndex = student.value.adks.findIndex(a => a.name === 'offer');
      if (
        student.value.adks[offerIndex].agreement &&
        student.value.adks[offerIndex].agreement.guardian &&
        !student.value.adks[offerIndex].agreement.guardian.pending_signup
      ) {
        isSigned.value = true;
      }
    }

    function setGuardianValidation(val) {
      isGuardianInvalid.value = val;
    }

    function setGuardianPhoneValidation(val) {
      isGuardianPhoneValid.value = val;
    }

    function getGuardianData(val) {
      guardianData.value = {
        guardian_first_name: val.first_name,
        guardian_last_name: val.last_name,
        guardian_phone: phonePayload.value.formatted_number,
        guardian_email: val.email,
        guardian_relationship: val.relationship,
        guardian_street_address: val.streetAddress,
        social_security_number: val.social_security_number,
        signature: val.signature
      };
    }

    function setTerms(val) {
      guardianTerms.value = val;
    }

    function setUserTerms(val) {
      userTerms.value = val;
    }

    function setUserValidation(val) {
      isUserInvalid.value = val;
    }

    function setUserPhoneValidation(val) {
      isUserPhoneValid.value = val;
    }

    function getUserData(val) {
      studentData.value = { ...val };
    }

    function setSSNValidation(val) {
      isSSN.value = val;
    }

    function signupValidate(val) {
      isSignupValid.value = val;
    }

    function getSignupPayload(val) {
      phonePayload.value = { ...val };
    }

    async function fetchConsentDetails() {
      const API = process.env.VUE_APP_AGREEMENT_SERVICE;

      try {
        fetching.value = true;
        const res = await axios.get(`${API}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data) {
          agreementContent.value = res.data.agreement_consent_text;
          fetching.value = false;
        }
      } catch (error) {
        console.log(error);
        fetching.value = false;
      }
    }

    fetchConsentDetails();

    watch(collection, async val => {
      if (val) {
        fetching.value = true;
        await getStudent();
        fetching.value = false;
      }
    });

    getStudent();

    async function acceptOffer() {
      try {
        submitting.value = true;
        const API = process.env.VUE_APP_AGREEMENT_SERVICE;
        const payload = {
          type: 'guardian',
          data: {
            program_id: student.value.program_id.toString(),
            user_id: student.value.participant_id.toString(),
            signed_agreement_content: agreementContent.value,
            consent_date_time: new Date(),
            ...guardianData.value,
            ...studentData.value
          }
        };
        payload.data.guardian_phone = phonePayload.value.formatted_number;
        const res = await axios.post(API, payload);

        if (res.data) {
          offerAlert.value.msg = 'Offer agreement has been signed electronically';
          offerAlert.value.color = 'green';
          if (user.value) {
            ctx.root.$router.push({ name: 'portfolio' });
            await signUp.value.removeGuardianFlag();
          } else {
            await signUp.value.submit();
          }
          offerAlert.value.msg = '';
          offerAlert.value.color = 'green';
          submitting.value = false;
        }
      } catch (error) {
        offerAlert.value.msg = error?.response?.data?.error?.description;
        offerAlert.value.color = 'red';
        submitting.value = false;
      }
    }

    return {
      setGuardianPhoneValidation,
      setUserTerms,
      userTerms,
      setUserValidation,
      setUserPhoneValidation,
      setTerms,
      getUserData,
      getGuardianData,
      offerAlert,
      acceptOffer,
      setGuardianValidation,
      isUserInvalid,
      isGuardianInvalid,
      isUserPhoneValid,
      isGuardianPhoneValid,
      setSSNValidation,
      agreementContent,
      submitting,
      guardianTerms,
      user,
      fetching,
      studentData,
      isSSN,
      signupValidate,
      isSignupValid,
      getSignupPayload,
      signUp,
      student,
      isSigned
    };
  }
});
