

















import { computed } from '@vue/composition-api';
import '@trevoreyre/autocomplete-vue/dist/style.css';

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return { inputValue };
  }
};
