


































































































































































































































































































import { reactive, toRefs, ref, SetupContext, Ref, computed } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/user/actions';
import { useAuthActions, useDbGetters, useUserGetters, useUserActions } from '@/store';
import axios from 'axios';
import { onLogin } from '@/vue-apollo';
import AOtpInput from '@/components/atoms/AOtpInput.vue';
import ATeleInput from '@/components/atoms/ATeleInput.vue';

interface IUserEmailForm {
  email: string;
  password: string;
  type: string;
  terms: boolean;
}

interface IUserPhoneForm {
  phone_number: string;
  type: string;
  terms: boolean;
}

interface IUi {
  msg: string;
  type: string;
  loading: boolean;
}

export default {
  name: 'Signup',
  components: {
    AOtpInput,
    ATeleInput
  },

  setup(props: any, context: SetupContext) {
    const { signup } = useAuthActions(['signup']);
    const { collection } = useDbGetters(['collection']);
    const { loginUser } = useUserActions([ActionTypes.loginUser]);
    const otpMsg = ref('');
    const dialog: Ref<boolean> = ref(false);
    const show1: Ref<boolean> = ref(false);
    const isOtpSent = ref(false);
    const isEmail = ref(false);
    const isPhone = ref(true);
    const verifying = ref(false);
    const signingIn = ref(false);
    const isPhoneValid = ref(false);
    const otp = ref('');
    const token = ref('');
    const captchaInput = ref('');
    const captchaImg = ref('');
    const stateEmail = reactive({
      email: '',
      password: '',
      type: 'email_signup',
      terms: false,
      error: ''
    });
    const statePhone = reactive({
      type: 'phone_number_signup',
      phone_number: '',
      formatted_number: '',
      terms: false
    });
    const {
      root: { $route, $router }
    } = context;
    const redirectPath = $route.query?.redirect;

    if (context.root.$route.query.otpSent) {
      isOtpSent.value = context.root.$route?.query?.otpSent;
      captchaImg.value = context.root.$route?.query?.captchaImg;
      statePhone.formatted_number = context.root.$route?.query?.phone;
    }

    const param: string = (context.root.$route.query.email as string) || '';
    stateEmail.email = param;

    const ui: IUi = reactive({
      msg: '',
      type: 'success',
      loading: false
    });

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted) {
        statePhone.formatted_number = `+${val.countryCallingCode}${val.formatted}`;
        isPhoneValid.value = val.valid;
      } else {
        statePhone.formatted_number = '';
      }
    }

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (
        statePhone.phone_number.includes(substring) ||
        statePhone.phone_number.includes(substring1)
      ) {
        return false;
      }

      return true;
    });

    const isValidOtp = computed(() => {
      if (otp.value.length === 6 && captchaInput.value.length === 4) {
        return true;
      }
      return false;
    });

    const login = async (val): Promise<void> => {
      try {
        const user = await loginUser(val);
        signingIn.value = false;
        try {
          await onLogin(token.value);
        } catch (err) {
          console.log(err);
          signingIn.value = false;
        }
        verifying.value = false;
        if ($route.params.page) $router.push({ path: $route.params.page });
        else if ($route.query.redirect === 'guide') {
          const { collection } = useDbGetters(['collection']);
          const { getObjectId } = useUserGetters(['getObjectId']);
          await collection.value!('Program')
            .insertOne({
              organizers: [getObjectId.value],
              participants: [],
              dateCreated: new Date(),
              licensed: false
            })
            .then(result => {
              $router.push({
                name: 'guide',
                params: { programId: result.insertedId, page: '0' }
              });
            });
        } // $router.push({ path: $route.query.redirect });
        else $router.push({ name: 'setup' });
      } catch (err) {
        if (err.statusCode === 401)
          stateEmail.error = 'That email and password combination does not exists';
        else stateEmail.error = err;
      }
    };

    async function verifyToken(val) {
      token.value = val;
      try {
        const data = {
          token: val
        };

        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/verify-jwt`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        collection.value!('User')
          .findOne({
            userId: resp.data.sub
          })
          .then(user => {
            login(user);
          });
      } catch (error) {
        console.log(error);
        otpMsg.value = 'Something went wrong';
        ui.type = 'error';
        signingIn.value = false;
        verifying.value = false;
      }
    }

    async function verifyOtp() {
      try {
        verifying.value = true;
        const data = {
          type: 'phone_number_verify',
          phone_number: statePhone.formatted_number,
          captcha_code: captchaInput.value,
          code: otp.value
        };
        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/verify-otp`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.status === 200) {
          ui.type = 'success';
          otpMsg.value = resp.data.message;
          verifyToken(resp.data.data.access_token);
        }
      } catch (error) {
        otpMsg.value = error?.response?.data?.error?.description
          ? error?.response?.data?.error?.description
          : 'Could not verify OTP';
        ui.type = 'error';
        verifying.value = false;
      }
    }

    const submit = async (): Promise<void> => {
      ui.loading = true;

      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTH0;

        const emailPayload = {
          ...stateEmail,
          email: stateEmail.email.toLowerCase()
        };

        const phnPayload = {
          ...statePhone,
          phone_number: statePhone.formatted_number
        };

        const data = isEmail.value ? emailPayload : phnPayload;

        const resp = await axios.post(`${API_ENDPOINT}/sign-up`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (resp.status === 200 && isPhone.value) {
          ui.type = 'success';
          ui.msg = resp.data.message;
          captchaImg.value = resp.data.data.captcha;
          isOtpSent.value = true;
        } else {
          ui.type = 'success';
          ui.msg = resp.data.message;
        }
      } catch (error) {
        ui.msg = error?.response?.data?.error?.description
          ? error?.response?.data?.error?.description
          : 'Could not signup';
        ui.type = 'error';
      }
      ui.loading = false;
    };
    const fetching = ref(false);
    async function reCaptcha() {
      try {
        fetching.value = true;
        const API_ENDPOINT = process.env.VUE_APP_AUTH0;
        const data = {
          phone_number: statePhone.formatted_number
        };

        const resp = await axios.post(`${API_ENDPOINT}/resend-captcha`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        console.log(resp.data);
        if (resp.data) {
          captchaImg.value = resp.data.captcha;
          fetching.value = false;
        }
      } catch (error) {
        fetching.value = false;
        console.log(error);
      }
    }

    return {
      ...toRefs(stateEmail),
      ...toRefs(statePhone),
      ...toRefs(ui),
      submit,
      reCaptcha,
      isEmail,
      dialog,
      show1,
      redirectPath,
      fetching,
      isOtpSent,
      isPhone,
      otp,
      verifyOtp,
      verifying,
      otpMsg,
      isValidOtp,
      isPhoneValid,
      getValidation,
      isPhoneCheck,
      captchaImg,
      captchaInput
    };
  }
};
